import React from 'react';
import { Row, Col } from 'reactstrap';

import img1 from '../../assets/images/img1.jpeg';
import img2 from '../../assets/images/img2.jpeg';
import img3 from '../../assets/images/img3.jpeg';
import img4 from '../../assets/images/img4.jpeg';
import img5 from '../../assets/images/img5.jpeg';
import img6 from '../../assets/images/img6.jpeg';
import './styles.scss';

const About = () => {
  return (
    <div id="about-container">
        <div className="mt-4">
          <Row>
            <Col xs="12" sm="8" md="8" lg={{ size: 5, offset: 2 }}>
              <h4>Historia</h4>
              <div>
                Escuela de ciencias y artes cinematográficas es una institución privada, fundada la fecha 11 de octubre de 2010 con resolución prefectural 306/10, situada en la ciudad de Cochabamba Bolivia.
              </div>
              <div>
                Escuela de ciencias y artes cinematográficas nace con la misión de formar, potenciar y empoderar profesionales de alta calidad personal y laboral, mediante la investigación creación y gestión de las Artes. proporcionando el conocimiento técnico, valores y herramientas necesarias para lograr el éxito, contribuyendo al desarrollo cultural y democrático del país.
              </div>
              <div>
                Fundamentada en valores que prioriza la ética, la solidaridad, la responsabilidad y el respeto, así también fortalece las capacidades propias de cada estudiante.
              </div>
            </Col>
            <Col xs="12" sm="4" md="4" lg="3" className="d-flex flex-column align-content-between">
              <div>
                <img src={img1} alt="descp1"/>
              </div>
              <div className="mt-2">
                <img src={img2} alt="descp2"/>
              </div>
            </Col>
          </Row>
        </div>

        <div className="mt-4">
          <Row>
            <Col xs="12" sm="8" md="8" lg={{ size: 5, offset: 2 }}>
              <div className="mb-3">
                <h4>Mision</h4>
                <span>
            Somos una institución que trabaja para formar, potenciar y empoderar profesionales de alta calidad personal y laboral, mediante la investigación creación y gestión de las Artes. proporcionando el conocimiento técnico, valores y herramientas necesarias para lograr el éxito, contribuyendo al desarrollo cultural y democrático del país.
          </span>
              </div>
            </Col>
            <Col xs="12" sm="4" md="4" lg="3" className="d-flex flex-column align-content-between">
              <div>
                <img src={img5} alt="descp3"/>
              </div>
            </Col>
          </Row>
        </div>

        <div className="mt-4">
          <Row>
            <Col xs="12" sm="8" md="8" lg={{ size: 5, offset: 2 }}>
              <div className="mb-3">
                <h4>Vision</h4>
                <span>
              Ser reconocida como la institución de Educación de mayor prestigio, gracias a su excelencia académica y calidad en formación profesional, brindando un ambiente institucional donde estudiantes, docentes y administrativos desarrollen al máximo su talento.
            </span>
              </div>
            </Col>
            <Col xs="12" sm="4" md="4" lg="3" className="d-flex flex-column align-content-between">
              <div>
                <img src={img6} alt="descp3"/>
              </div>
            </Col>
          </Row>
        </div>
    </div>
  );
};

About.propTypes = {};

export default About;