import React from 'react';
import { Row, Col } from 'reactstrap';

import produccionAudiovisual from '../../assets/images/careers/0001.jpg';
import sonido from '../../assets/images/careers/0002.jpg';
import disenioGrafico from '../../assets/images/careers/0003.jpg';

import './styles.scss';

const Careers = () => {
  return (
    <div id="careers-container">
      <div className="mt-4">
        <Row>
          <Col xs="12" sm="8" md="8" lg={{ size: 5, offset: 2 }}>
            <h4>SONIDO</h4>
            <div className="mt-3">
              <h5>DESCRIPCIÓN DE LA CARRERA</h5>
              <div className="mt-2">
                El Técnico Superior en Sonido está capacitado para desempeñarse en las diferentes áreas referidas a la tecnología del sonido; con el conocimiento y práctica suficientes que le permitan cumplir con funciones propias en sistemas de audio, estudios de grabación, radio, cine, multimedia y televisión; además de contar con la base suficiente para adaptarse a las nuevas necesidades del mercado y la tecnología.
              </div>
            </div>
            <div className="mt-3">
              <h5>CAMPO LABORAL</h5>
              <div className="mt-2">
                Podrá desempeñarse en empresas de cine, radio y televisión; estudios de grabación, productoras de sonido y multimedia, empresas que prestan asesoramiento en control de ruido y medio ambiente. También podrá realizar el diseño sonoro para cantantes o bandas y acondicionamiento acústico de salas de eventos y espectáculo. Además, será capaz de diseñar e implementar micro empresas para brindar servicios en el área.
              </div>
            </div>
          </Col>
          <Col xs="12" sm="4" md="4" lg="3" className="mt-auto mb-auto">
            <img src={sonido} alt="carrera-sonido-pensum"/>
          </Col>
        </Row>
      </div>
      <div className="mt-4">
        <Row>
          <Col xs="12" sm="8" md="8" lg={{ size: 5, offset: 2 }}>
            <h4>DISEÑO GRAFICO</h4>
            <div className="mt-3">
              <h5>DESCRIPCIÓN DE LA CARRERA</h5>
              <div className="mt-2">
                El Técnico superior en Diseño Gráfico es un profesional capaz de desarrollar, implementar y supervisar proyectos gráficos, editoriales, publicitarios y de imagen corporativa, empleando eficazmente herramientas tecnológicas de diseño para entregar productos creativos y de alto impacto.
              </div>
            </div>
            <div className="mt-3">
              <h5>CAMPO LABORAL</h5>
              <div className="mt-2">
                Se desempeña en cargos directivos en agencias de publicidad, diseño de productos y envases en sectores productivos, supervisor de producción en industrias de impresión, constructor de modelos y prototipos, ilustrador digital, diseñador en empresas editoriales de libros, periódicos y revistas; imagen corporativa en empresas públicas, privadas y organismos no gubernamentales; también puede desempeñarse como diseñador independiente generando y gestionando su propia micro empresa.
              </div>
            </div>
          </Col>
          <Col xs="12" sm="4" md="4" lg="3" className="mt-auto mb-auto">
            <img src={disenioGrafico} alt="carrera-disenio-grafico-pensum"/>
          </Col>
        </Row>
      </div>
      <div className="mt-4">
        <Row>
          <Col xs="12" sm="8" md="8" lg={{ size: 5, offset: 2 }}>
            <h4>PRODUCCION AUDIOVISUAL</h4>
            <div className="mt-3">
              <h5>DESCRIPCIÓN DE LA CARRERA</h5>
              <div className="mt-2">
                El Técnico Superior en Producción Audiovisual se especializa en la producción, dirección, grabación y Post – producción de televisión, cine y video; con dominio creativo del lenguaje de la imagen y sonido; siendo competente para planificar, organizar, supervisar y gestionar los recursos técnicos y humanos para la producción de audiovisuales.
              </div>
            </div>
            <div className="mt-3">
              <h5>CAMPO LABORAL</h5>
              <div className="mt-2">
                Se desempeña como editor, director y productor en empresas de cine, televisión y video; cuenta con conocimiento técnico para trabajar en medios de comunicación, agencias de publicidad y productoras audiovisuales. Además de generar su espacio de trabajo a través de la generación y gestión de micro empresas y prestación de servicios audiovisuales.
              </div>
            </div>
          </Col>
          <Col xs="12" sm="4" md="4" lg="3" className="mt-auto mb-auto">
            <img src={produccionAudiovisual} alt="carrera-produccion-audiovisual-pensum"/>
          </Col>
        </Row>
      </div>
    </div>
  );
};

Careers.propTypes = {};

export default Careers;