import React, { useState } from 'react';
import { Nav, NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';

import './styles.scss';

const Tabs = Object.freeze({
  ABOUT_US: 'aboutUs',
  CONTACT: 'contact',
  CAREERS: 'careers'
});

const TopBar = () => {
  const [activeNav, setActiveNav] = useState(null);
  const [navHovered, setNavHovered] = useState(null);

  const onToggleTab = (item) => {
    setActiveNav(item);
  };

  const onHoverNav = (item) => {
    setNavHovered(item);
  };

  const resetHoveredNav = () => {
    setNavHovered(null);
  };

  return (
    <section id="top-bar">
      <Nav className="top-bar d-flex align-items-center" tabs>
        <NavItem className={`${navHovered === Tabs.ABOUT_US ? '' : 'py-3'}`}>
          <NavLink
            to="/"
            onClick={() => onToggleTab(Tabs.ABOUT_US)}
            className={`${activeNav === Tabs.ABOUT_US ? 'active' : ''} ${navHovered === Tabs.ABOUT_US ? 'btn btn-primary' : ''}`}
            onMouseEnter={() => onHoverNav(Tabs.ABOUT_US)}
            onMouseLeave={resetHoveredNav}
          >
            Quienes somos
          </NavLink>
        </NavItem>
        {/*<NavItem className={`${navHovered === Tabs.CONTACT ? '' : 'py-3'}`}>*/}
        {/*  <NavLink*/}
        {/*    to="/contact"*/}
        {/*    onClick={() => onToggleTab(Tabs.CONTACT)}*/}
        {/*    className={`${activeNav === Tabs.CONTACT ? 'active' : ''} ${navHovered === Tabs.CONTACT ? 'btn btn-primary' : ''}`}*/}
        {/*    onMouseEnter={() => onHoverNav(Tabs.CONTACT)}*/}
        {/*    onMouseLeave={resetHoveredNav}*/}
        {/*  >*/}
        {/*    Contacto*/}
        {/*  </NavLink>*/}
        {/*</NavItem>*/}
        <NavItem className={`${navHovered === Tabs.CAREERS ? '' : 'py-3'}`}>
          <NavLink
            to="/careers"
            onClick={() => onToggleTab(Tabs.CAREERS)}
            className={`${activeNav === Tabs.CAREERS ? 'active' : ''}  ${navHovered === Tabs.CAREERS ? 'btn btn-primary' : ''}`}
            onMouseEnter={() => onHoverNav(Tabs.CAREERS)}
            onMouseLeave={resetHoveredNav}
          >
            Carreras
          </NavLink>
        </NavItem>
      </Nav>
    </section>
  );
};

TopBar.propTypes = {

};

export default TopBar;