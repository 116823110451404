import React from 'react';
import logo from '../../../assets/images/logo.jpeg'

import './styles.scss';

const Header = () => {
  return (
    <div className="header d-flex align-items-center">
      <div className="logo-container">
        <img src={logo} alt="Excuela de ciencias y artes cinematograficas" width="100"/>
      </div>
      <div className="flex-grow-1 text-center">
        <h4>Escuela de Ciencias y Artes Cinematograficas</h4>
      </div>
    </div>
  );
};

Header.propTypes = {};

Header.defaultProps = {};

export default Header;