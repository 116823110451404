import React from 'react';

import Header from './Header';
import TopBar from './TopBar';
import Footer from './Footer';
import './styles.scss'

const Layout = ({ children }) => {
  return (
    <div className="initial-container">
      <div className="main-container position-relative">
        <div className="content">
        <Header />
        <TopBar />

          <div className="principal-content">
            {children}
          </div>

          <Footer />
        </div>
      </div>
    </div>
  );
};

Layout.propTypes = {};

Layout.defaultProps = {};

export default Layout;