import React from 'react';
import { Route, Routes } from 'react-router-dom';

import About from './About';
import Careers from './Careers';
import '../assets/css/theme.scss';
import '../assets/css/commons.scss';

import Layout from '../layouts/Default';

const Main = () => {
  return (
    <div>
      <Layout>
        <Routes>
          <Route exact path="/" element={<About />} />
          <Route exact path="/careers" element={<Careers />} />
        </Routes>
      </Layout>
    </div>
  );
};

Main.propTypes = {

};

export default Main;